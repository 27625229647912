<template>
    <div class="stepsdemo-content">
        <Card>            
            <template v-slot:content>
                <div class="grid p-fluid">
                    <div class="col-12 lg:col-10 lg:col-offset-1">
                        <div class="field" >
                            <label for="title">Title <span style="color: #ff4444">*</span></label>
                            <InputText
                                :class="{'p-invalid': valid$.title.$invalid && submitted}"
                                id="title" 
                                type="text" 
                                v-model="valid$.title.$model" 
                                placeholder="Tour Title Here"
                            />
                            <span v-if="valid$.title.$error && submitted">
                                <span
                                    id="title-error"
                                    v-for="(error, index) of valid$.title.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Title")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.title.$invalid && submitted) || valid$.title.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.title.required.$message.replace("Value", "Title") }}
                            </small>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-12 lg:col-5">
                                <div class="grid p-fluid">
                                    <div class="col-12">
                                        <Divider align="left" type="dashed">
                                            <b>Starting Address</b>
                                        </Divider>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field" >
                                            <label for="country">Country <span style="color: #ff4444">*</span></label>
                                            <InputText
                                                :class="{'p-invalid': valid$.starting_point.country.$invalid && submitted}"
                                                id="country" 
                                                type="text" 
                                                v-model="valid$.starting_point.country.$model"
                                                disabled
                                            />
                                            <span v-if="valid$.starting_point.country.$error && submitted">
                                                <span
                                                    id="country-error"
                                                    v-for="(error, index) of valid$.starting_point.country.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Country")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.starting_point.country.$invalid && submitted) || valid$.starting_point.country.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.starting_point.country.required.$message.replace("Value", "Country") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field">
                                            <label for="division">Division <span style="color: #ff4444">*</span></label>
                                            <Dropdown
                                                :class="{'p-invalid': valid$.starting_point.division.$invalid && submitted}"
                                                id="division"
                                                @change="getstartingDistrict(valid$.starting_point.division.$model)"
                                                v-model="valid$.starting_point.division.$model"
                                                :options="startingDivisionList"
                                                :filter="true"
                                                optionLabel="value"
                                                optionValue="value"
                                                placeholder="Select your Division"
                                            />
                                            <span v-if="valid$.starting_point.division.$error && submitted">
                                                <span
                                                    id="division-error"
                                                    v-for="(error, index) of valid$.starting_point.division.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Division")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.starting_point.division.$invalid && submitted) || valid$.starting_point.division.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.starting_point.division.required.$message.replace("Value", "Division") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field">
                                            <label for="district">District <span style="color: #ff4444">*</span></label>
                                            <Dropdown
                                                :class="{'p-invalid': valid$.starting_point.district.$invalid && submitted}"
                                                id="district"
                                                @change="getstartingThana(valid$.starting_point.district.$model)"
                                                v-model="valid$.starting_point.district.$model"
                                                :options="startingDistrictList"
                                                :filter="true"
                                                optionLabel="value"
                                                optionValue="value"
                                                placeholder="Select your District"
                                            />
                                            <span v-if="valid$.starting_point.district.$error && submitted">
                                                <span
                                                    id="district-error"
                                                    v-for="(error, index) of valid$.starting_point.district.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "District")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.starting_point.district.$invalid && submitted) || valid$.starting_point.district.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.starting_point.district.required.$message.replace("Value", "District") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field">
                                            <label for="thana">Thana <span style="color: #ff4444">*</span></label>
                                            <Dropdown
                                                :class="{'p-invalid': valid$.starting_point.thana.$invalid && submitted}"
                                                id="thana"
                                                v-model="valid$.starting_point.thana.$model"
                                                :options="startingThanaList"
                                                :filter="true"
                                                optionLabel="value"
                                                optionValue="value"
                                                placeholder="Select your Thana"
                                            />
                                            <span v-if="valid$.starting_point.thana.$error && submitted">
                                                <span
                                                    id="thana-error"
                                                    v-for="(error, index) of valid$.starting_point.thana.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Thana")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.starting_point.thana.$invalid && submitted) || valid$.starting_point.thana.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.starting_point.thana.required.$message.replace("Value", "Thana") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="field">
                                            <label for="line">Address <span style="color: #ff4444">*</span></label>
                                            <InputText
                                                id="line"
                                                type="text"
                                                v-model="valid$.starting_point.line.$model"
                                                placeholder="Address line"
                                                :class="{'p-invalid': valid$.starting_point.line.$invalid && submitted}"
                                            />
                                            <span v-if="valid$.starting_point.line.$error && submitted">
                                                <span
                                                    id="line-error"
                                                    v-for="(error, index) of valid$.starting_point.line.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Address")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.starting_point.line.$invalid && submitted) || valid$.starting_point.line.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.starting_point.line.required.$message.replace("Value", "Address") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 lg:col-5 lg:col-offset-2">
                                <div class="grid p-fluid">
                                    <div class="col-12">
                                        <Divider align="left" type="dashed">
                                            <b>Destination Address</b>
                                        </Divider>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field">
                                            <label for="country">Country <span style="color: #ff4444">*</span></label>
                                            <InputText
                                                :class="{'p-invalid': valid$.destination.country.$invalid && submitted}"
                                                id="country" 
                                                type="text" 
                                                v-model="valid$.destination.country.$model"
                                                disabled
                                            />
                                            <span v-if="valid$.destination.country.$error && submitted">
                                                <span
                                                    id="country-error"
                                                    v-for="(error, index) of valid$.destination.country.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Country")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.destination.country.$invalid && submitted) || valid$.destination.country.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.destination.country.required.$message.replace("Value", "Country") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field">
                                            <label for="division">Division <span style="color: #ff4444">*</span></label>
                                            <Dropdown
                                                :class="{'p-invalid': valid$.destination.division.$invalid && submitted}"
                                                id="division"
                                                @change="getDestinationDistrict(valid$.destination.division.$model)"
                                                v-model="valid$.destination.division.$model"
                                                :options="destinationDivisionList"
                                                :filter="true"
                                                optionLabel="value"
                                                optionValue="value"
                                                placeholder="Select your Division"
                                            />
                                            <span v-if="valid$.destination.division.$error && submitted">
                                                <span
                                                    id="division-error"
                                                    v-for="(error, index) of valid$.destination.division.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Division")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.destination.division.$invalid && submitted) || valid$.destination.division.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.destination.division.required.$message.replace("Value", "Division") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field" >
                                            <label for="district">District <span style="color: #ff4444">*</span></label>
                                            <Dropdown
                                                :class="{'p-invalid': valid$.destination.district.$invalid && submitted}"
                                                id="district"
                                                @change="getDestinationThana(valid$.destination.district.$model)"
                                                v-model="valid$.destination.district.$model"
                                                :options="destinationDistrictList"
                                                :filter="true"
                                                optionLabel="value"
                                                optionValue="value"
                                                placeholder="Select your District"
                                            />
                                            <span v-if="valid$.destination.district.$error && submitted">
                                                <span
                                                    id="district-error"
                                                    v-for="(error, index) of valid$.destination.district.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "District")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.destination.district.$invalid && submitted) || valid$.destination.district.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.destination.district.required.$message.replace("Value", "District") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12 lg:col-6">
                                        <div class="field">
                                            <label for="thana">Thana <span style="color: #ff4444">*</span></label>
                                            <Dropdown
                                                :class="{'p-invalid': valid$.destination.thana.$invalid && submitted}"
                                                id="thana"
                                                v-model="valid$.destination.thana.$model"
                                                :options="destinationThanaList"
                                                :filter="true"
                                                optionLabel="value"
                                                optionValue="value"
                                                placeholder="Select your Thana"
                                            />
                                            <span v-if="valid$.destination.thana.$error && submitted">
                                                <span
                                                    id="thana-error"
                                                    v-for="(error, index) of valid$.destination.thana.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Thana")}}</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.destination.thana.$invalid && submitted) || valid$.destination.district.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.destination.thana.required.$message.replace("Value", "Thana") }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="field">
                                            <label for="line">Address <span style="color: #ff4444">*</span></label>
                                            <InputText
                                                :class="{'p-invalid': valid$.destination.line.$invalid && submitted}"
                                                id="line"
                                                type="text"
                                                v-model="valid$.destination.line.$model"
                                                placeholder="Address line"
                                            />
                                            <span v-if="valid$.destination.line.$error && submitted">
                                                <span
                                                    id="line-error"
                                                    v-for="(error, index) of valid$.destination.line.$errors"
                                                    :key="index"
                                                >
                                                    <small class="p-error">{{error.$message.replace("Value", "Address")}} A</small>
                                                </span>
                                            </span>
                                            <small
                                                v-else-if="(valid$.destination.line.$error && submitted || valid$.destination.line.$invalid && submitted) || valid$.destination.line.$pending.$response"
                                                class="p-error"
                                            >
                                                {{ valid$.destination.line.required.$message.replace("Value", "Address") }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider align="left" type="dashed" class="mt-4">
                            <b>Places</b>
                        </Divider>
                        <div class="flex flex-wrap mb-2">
                            <div class="flex flex-grow-1 align-items-center">
                                Write all the places to be visited in this tour. Example: Saint martin, Chhera dwip, Naaf river etc.
                            </div>
                            <div class="flex m-2">
                                <Button
                                    @click="addPlaces"
                                    icon="pi pi-plus"
                                    label="Add Place"
                                    class="p-button p-button-text p-button-raised w-full"
                                />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col-12 lg:col-4" v-for="(item, index) in places" :key="index">
                                <div class="p-inputgroup">
                                    <InputText
                                        :class="{'p-invalid': item.length < 1 && submitted}"
                                        id="places"
                                        v-model="places[index]"
                                        placeholder="Place name"
                                    />
                                    <Button
                                        icon="pi pi-times"
                                        v-if="places.length != 1"
                                        class="p-button-danger"
                                        @click="deletePlaces(index)"
                                    />
                                </div>
                                <span v-if="item.length < 1 && submitted">
                                    <small class="p-error">Place is required</small>
                                </span>
                            </div>
                        </div>
                        <div class="grid mt-4">
                            <Divider align="left" type="dashed">
                                <b>Details</b>
                            </Divider>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="start_date">Start Date <span style="color: #ff4444">*</span></label>
                                    <Calendar
                                        :class="{'p-invalid': valid$.start_date.$invalid && submitted}"
                                        id="start_date"
                                        v-model="valid$.start_date.$model"
                                        dateFormat="dd-mm-yy"
                                        :showButtonBar="true"
                                        selectionMode="single"
                                        :showIcon="true"
                                        :maxDate="valid$.end_date.$model"
                                        :minDate="current"
                                        @date-select="changeStartDate"
                                    />
                                    <span v-if="valid$.start_date.$error && submitted">
                                        <span
                                            id="title-error"
                                            v-for="(error, index) of valid$.start_date.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Start Date")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.start_date.$invalid && submitted) || valid$.start_date.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.start_date.required.$message.replace("Value", "Start Date") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="end_date">End Date <span style="color: #ff4444">*</span></label>
                                    <Calendar
                                        v-if="valid$.start_date.$model"
                                        :class="{'p-invalid': valid$.end_date.$invalid && submitted}"
                                        id="end_date"
                                        v-model="valid$.end_date.$model"
                                        dateFormat="dd-mm-yy"
                                        :showIcon="true"
                                        :minDate="valid$.start_date.$model"
                                    />
                                    <Calendar v-else disable showIcon v-tooltip.top="'Enter Start Date First'"/>
                                    <span v-if="valid$.end_date.$error && submitted">
                                        <span
                                            id="title-error"
                                            v-for="(error, index) of valid$.end_date.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "End Date")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.end_date.$invalid && submitted) || valid$.end_date.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.end_date.required.$message.replace("Value", "End Date") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="booking_deadline">Booking Deadline <span style="color: #ff4444">*</span></label>
                                    <Calendar
                                        v-if="valid$.start_date.$model"
                                        :class="{'p-invalid': valid$.booking_deadline.$invalid && submitted}"
                                        id="booking_deadline"
                                        v-model="valid$.booking_deadline.$model"
                                        dateFormat="dd-mm-yy"
                                        :showIcon="true"
                                        :minDate="current"
                                        :maxDate="valid$.start_date.$model"
                                    />
                                    <Calendar v-else disable showIcon v-tooltip.top="'Enter Start Date First'"/>
                                    <span v-if="valid$.booking_deadline.$error && submitted">
                                        <span
                                            id="booking_deadline-error"
                                            v-for="(error, index) of valid$.booking_deadline.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Booking Deadline")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.booking_deadline.$invalid && submitted) || valid$.booking_deadline.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.booking_deadline.required.$message.replace("Value", "Booking Deadline") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="cancellation_deadline">Cancellation Deadline <span style="color: #ff4444">*</span></label>
                                    <Calendar
                                        v-if="valid$.start_date.$model"
                                        :class="{'p-invalid': valid$.cancellation_deadline.$invalid && submitted}"
                                        id="cancellation_deadline"
                                        v-model="valid$.cancellation_deadline.$model"
                                        dateFormat="dd-mm-yy"
                                        :showIcon="true"
                                        :minDate="current"
                                        :maxDate="valid$.start_date.$model"
                                    />
                                    <Calendar v-else disable showIcon v-tooltip.top="'Enter Start Date First'"/>
                                    <span v-if="valid$.cancellation_deadline.$error && submitted">
                                        <span
                                            id="cancellation_deadline-error"
                                            v-for="(error, index) of valid$.cancellation_deadline.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Cancellation Period")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.cancellation_deadline.$invalid && submitted) || valid$.cancellation_deadline.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.cancellation_deadline.required.$message.replace("Value", "Cancellation Period") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-6 lg:col-2">
                                <div class="field">
                                    <label for="seat_total">Total Seat <span style="color: #ff4444">*</span></label>
                                    <InputNumber 
                                        :class="{'p-invalid': valid$.seat_total.$invalid && submitted}"
                                        id="seat_total"
                                        v-model="valid$.seat_total.$model"
                                        :min="0"
                                        :step="1"
                                    />
                                    <span v-if="valid$.seat_total.$error && submitted">
                                        <span
                                            id="seat_total-error"
                                            v-for="(error, index) of valid$.seat_total.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Total Seat")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.seat_total.$invalid && submitted) || valid$.seat_total.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.seat_total.required.$message.replace("Value", "Total Seat") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-6 lg:col-2">
                                <div class="field">
                                    <label for="seat_booked">Booked Seat</label>
                                    <InputNumber 
                                        :class="{'p-invalid': valid$.seat_booked.$invalid && submitted}"
                                        id="seat_booked"
                                        v-model="valid$.seat_booked.$model"
                                        :min="0"
                                        :max="valid$.seat_total.$model"
                                        :step="1" 
                                    />
                                    <span v-if="valid$.seat_booked.$error && submitted">
                                        <span
                                            id="seat_booked-error"
                                            v-for="(error, index) of valid$.seat_booked.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Booked Seat")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.seat_booked.$invalid && submitted) || valid$.seat_booked.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.seat_booked.required.$message.replace("Value", "Booked Seat") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-6 lg:col-2">
                                <div class="field">
                                    <label for="day">Number of Days <span style="color: #ff4444">*</span></label>
                                    <InputNumber 
                                        :class="{'p-invalid': valid$.day.$invalid && submitted}"
                                        id="day"
                                        v-model="valid$.day.$model"
                                        :min="0"
                                        :step="1"
                                    />
                                    <span v-if="valid$.day.$error && submitted">
                                        <span
                                            id="day-error"
                                            v-for="(error, index) of valid$.day.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Day")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.day.$invalid && submitted) || valid$.day.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.day.required.$message.replace("Value", "Day") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-6 lg:col-2">
                                <div class="field">
                                    <label for="night">Number of Nights <span style="color: #ff4444">*</span></label>
                                    <InputNumber
                                        :class="{'p-invalid': valid$.night.$invalid && submitted}"
                                        id="night"
                                        v-model="valid$.night.$model"
                                        :min="0"
                                        :step="1"
                                    />
                                    <span v-if="valid$.night.$error && submitted">
                                        <span
                                            id="night-error"
                                            v-for="(error, index) of valid$.night.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Night")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.night.$invalid && submitted) || valid$.night.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.night.required.$message.replace("Value", "Night") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-6 lg:col-2">
                                <div class="field">
                                    <label for="meals">Number of Meals <span style="color: #ff4444">*</span></label>
                                    <InputNumber 
                                        :class="{'p-invalid': valid$.meals.$invalid && submitted}"
                                        id="meals"
                                        v-model="valid$.meals.$model"
                                        :min="0"
                                        :step="1"
                                    />
                                    <span v-if="valid$.meals.$error && submitted">
                                        <span
                                            id="meals-error"
                                            v-for="(error, index) of valid$.meals.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Meals")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.meals.$invalid && submitted) || valid$.meals.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.meals.required.$message.replace("Value", "Meals") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-6 lg:col-2">
                                <div class="field">
                                    <label for="refund_percentage">Refund Percentage <span style="color: #ff4444">*</span></label>
                                    <div class="p-inputgroup">
                                        <InputNumber
                                            :class="{'p-invalid': valid$.refund_percentage.$invalid && submitted}"
                                            id="refund_percentage"
                                            v-model="valid$.refund_percentage.$model"
                                            :min="0"
                                            :max="100"
                                        />
                                        <span class="p-inputgroup-addon">%</span>
                                    </div>
                                    <span v-if="valid$.refund_percentage.$error && submitted">
                                        <span
                                            id="refund_percentage-error"
                                            v-for="(error, index) of valid$.refund_percentage.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Refund Percentage")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.refund_percentage.$invalid && submitted) || valid$.refund_percentage.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.refund_percentage.required.$message.replace("Value", "Refund Percentage") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="type_id">Type <span style="color: #ff4444">*</span></label>
                                    <Dropdown
                                        :class="{'p-invalid': valid$.type_id.$invalid && submitted}"
                                        v-model="valid$.type_id.$model"
                                        :options="types"
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Tour type" 
                                    />
                                    <span v-if="valid$.type_id.$error && submitted">
                                        <span
                                            id="type_id-error"
                                            v-for="(error, index) of valid$.type_id.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Type")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.type_id.$invalid && submitted) || valid$.type_id.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.type_id.required.$message.replace("Value", "Type") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="category_id">Catagory <span style="color: #ff4444">*</span></label>
                                    <Dropdown 
                                        :class="{'p-invalid': valid$.category_id.$invalid && submitted}"
                                        v-model="valid$.category_id.$model"
                                        :options="catagories"
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Tour Catagory"
                                    />
                                    <span v-if="valid$.category_id.$error && submitted">
                                        <span
                                            id="category_id-error"
                                            v-for="(error, index) of valid$.category_id.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Category")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.category_id.$invalid && submitted) || valid$.category_id.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.category_id.required.$message.replace("Value", "Category") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="manager_id">Manager <span style="color: #ff4444">*</span></label>
                                    <Dropdown
                                        :class="{'p-invalid': valid$.manager_id.$invalid && submitted}"
                                        v-model="valid$.manager_id.$model"
                                        :options="managers"
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Select Manager"
                                    />
                                    <span v-if="valid$.manager_id.$error && submitted">
                                        <span
                                            id="manager_id-error"
                                            v-for="(error, index) of valid$.manager_id.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Manager")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.manager_id.$invalid && submitted) || valid$.manager_id.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.manager_id.required.$message.replace("Value", "Manager") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="places">T-Shirt <span style="color: #ff4444">*</span></label>
                                    <Dropdown
                                        :class="{'p-invalid': valid$.tshirt.$invalid && submitted}"
                                        v-model="valid$.tshirt.$model"
                                        :options="[{label: 'Yes', value: 1}, {label: 'No', value: 0}]"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="T-Shirt provided" 
                                    />
                                    <span v-if="valid$.tshirt.$error && submitted">
                                        <span
                                            id="tshirt-error"
                                            v-for="(error, index) of valid$.tshirt.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "T-Shirt")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.tshirt.$invalid && submitted) || valid$.tshirt.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.tshirt.required.$message.replace("Value", "T-Shirt") }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <Divider align="left" type="dashed">
                            <b>Prices</b>
                        </Divider>
                        <div class="grid">
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="price_adults">Price Adults <span style="color: #ff4444">*</span></label>
                                    <InputNumber
                                        :class="{'p-invalid': valid$.price_adults.$invalid && submitted}"
                                        id="price_adults"
                                        v-model="valid$.price_adults.$model"
                                        prefix="BDT "
                                        :max="100000000"
                                    />
                                    <span v-if="valid$.price_adults.$error && submitted">
                                        <span
                                            id="price_adults-error"
                                            v-for="(error, index) of valid$.price_adults.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Adult Price")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.price_adults.$invalid && submitted) || valid$.price_adults.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.price_adults.required.$message.replace("Value", "Adult Price") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="discount_adults">Discount Price Adults</label>
                                    <InputNumber 
                                        :class="{'p-invalid': valid$.discount_adults.$invalid && submitted}"
                                        id="discount_adults"
                                        v-model="valid$.discount_adults.$model"
                                        prefix="BDT "
                                        :max="valid$.price_adults.$model"
                                    />
                                    <span v-if="valid$.discount_adults.$error && submitted">
                                        <span
                                            id="discount_adults-error"
                                            v-for="(error, index) of valid$.discount_adults.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Discount Adult Price")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.discount_adults.$invalid && submitted) || valid$.discount_adults.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.discount_adults.required.$message.replace("Value", "Discount Adult Price") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="price_children">Price Children <span style="color: #ff4444">*</span></label>
                                    <InputNumber
                                        :class="{'p-invalid': valid$.price_children.$invalid && submitted}"
                                        id="price_children"
                                        v-model="valid$.price_children.$model"
                                        prefix="BDT "
                                        :max="100000000"
                                    />
                                    <span v-if="valid$.price_children.$error && submitted">
                                        <span
                                            id="price_children-error"
                                            v-for="(error, index) of valid$.price_children.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Children Price")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.price_children.$invalid && submitted) || valid$.price_children.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.price_children.required.$message.replace("Value", "Children Price") }}
                                    </small>
                                </div>
                            </div>
                            <div class="col-12 lg:col-3">
                                <div class="field">
                                    <label for="discount_children">Discount Price Children</label>
                                    <InputNumber 
                                        :class="{'p-invalid': valid$.discount_children.$invalid && submitted}"
                                        id="discount_children"
                                        v-model="valid$.discount_children.$model"
                                        prefix="BDT "
                                        :max="valid$.price_children.$model"
                                    />
                                    <span v-if="valid$.discount_children.$error && submitted">
                                        <span
                                            id="discount_children-error"
                                            v-for="(error, index) of valid$.discount_children.$errors"
                                            :key="index"
                                        >
                                            <small class="p-error">{{error.$message.replace("Value", "Discount Children Price")}}</small>
                                        </span>
                                    </span>
                                    <small
                                        v-else-if="(valid$.discount_children.$invalid && submitted) || valid$.discount_children.$pending.$response"
                                        class="p-error"
                                    >
                                        {{ valid$.discount_children.required.$message.replace("Value", "Discount Children Price") }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <Divider align="left" type="dashed">
                            <b>Description</b>
                        </Divider>
                        <div class="field">
                            <Editor 
                                :class="{'p-invalid': valid$.description.$invalid && submitted}"
                                id="description"
                                v-model="valid$.description.$model"
                                :autoResize="true"
                                editorStyle="height: 120px"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                            <span v-if="valid$.description.$error && submitted">
                                <span
                                    id="description-error"
                                    v-for="(error, index) of valid$.description.$errors"
                                    :key="index"
                                >
                                    <small class="p-error">{{error.$message.replace("Value", "Description")}}</small>
                                </span>
                            </span>
                            <small
                                v-else-if="(valid$.description.$invalid && submitted) || valid$.description.$pending.$response"
                                class="p-error"
                            >
                                {{ valid$.description.required.$message.replace("Value", "Description") }}
                            </small>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex justify-content-between flex-wrap mb-4">
                    <div class="flex align-items-center justify-content-center"></div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Next" @click="nextPage(!valid$.$invalid)" icon="pi pi-angle-right" icon-pos="right" class="w-full px-6"></Button>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { reactive, ref} from 'vue';
import GlobalValues from '../../../data/GlobalValues';
import store from './../../../vuex';
import Axios from 'axios';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, minValue, maxValue, numeric } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
export default {
    setup(){
        const toast = useToast();
        const submitted = ref(false);
        const current = new Date();

        const globalValues = ref(new GlobalValues());

        const state = reactive({
            title: "",
            starting_point: {
                line: '',
                thana: '',
                district: '',
                division: '',
                country: 'Bangladesh',
            },
            destination: {
                line: '',
                thana: '',
                district: '',
                division: '',
                country: 'Bangladesh',
            },
            seat_total: 0,
            seat_booked: 0,
            start_date: "",
            end_date: "",
            day: 0,
            night: 0,
            meals: 0,
            tshirt: 0,
            type_id: "",
            category_id: "",
            manager_id: "",
            description: "",
            booking_deadline: "",
            cancellation_deadline: "",
            refund_percentage: 0,
            price_adults: 0,
            discount_adults: 0,
            price_children: 0,
            discount_children: 0,
        });

        const rules = {
            title: {required, maxLength: maxLength(255), minLength: minLength(3)},
            starting_point: {
                line: { required, maxLength: maxLength(255) },
                thana: { required, maxLength: maxLength(255) },
                district: { required, maxLength: maxLength(255) },
                division: { required, maxLength: maxLength(255) },
                country: { required, maxLength: maxLength(255) },
            },
            destination: {
                line: { required, maxLength: maxLength(255) },
                thana: { required, maxLength: maxLength(255) },
                district: { required, maxLength: maxLength(255) },
                division: { required, maxLength: maxLength(255) },
                country: { required, maxLength: maxLength(255) },
            },
            seat_total: {required, minValue: minValue(1)},
            seat_booked: {required, minValue: minValue(0)},
            start_date: {required},
            end_date: {required},
            day: { required, numeric, minValue: minValue(0) },
            night: { required, numeric, minValue: minValue(0) },
            meals: { required, numeric, minValue: minValue(0) },
            tshirt : { required },
            type_id: { required },
            category_id: { required },
            manager_id: { required },
            description: { },
            booking_deadline: { required },
            cancellation_deadline: { required },
            refund_percentage: { required, numeric, minValue: minValue(0), maxValue: maxValue(100) },
            price_adults: { required, minValue: minValue(0) },
            discount_adults: { minValue: minValue(0) },
            price_children: { required, minValue: minValue(0) },
            discount_children: { minValue: minValue(0) },
        }

        const valid$ = useVuelidate (rules, state);

        const places = ref(['']);
        const types = ref([]);
        const catagories = ref([]);
        const managers = ref([]);

        const startingDivisionList = ref();
        const destinationDivisionList = ref();
        const startingDistrictList = ref();
        const destinationDistrictList = ref();
        const destinationThanaList = ref();
        const startingThanaList = ref();

        const getstartingDivision = () => {
            startingDivisionList.value = globalValues.value.getDivisions(state.starting_point.country);

            state.starting_point.division = null;
            state.starting_point.district = null;
            state.starting_point.thana = null;
        }
        getstartingDivision();
        const getDestinationDivision = () => {
            destinationDivisionList.value = globalValues.value.getDivisions(state.destination.country);

            state.destination.division = null;
            state.destination.district = null;
            state.destination.thana = null;
        };
        getDestinationDivision();
        const getstartingDistrict = (division) => {
            startingDistrictList.value = globalValues.value.getDistricts(division);

            state.starting_point.district = null;
            state.starting_point.thana = null;
        };
        const getDestinationDistrict = (division) => {
            destinationDistrictList.value = globalValues.value.getDistricts(division);

            state.destination.district = null;
            state.destination.thana = null;
        };
        const getstartingThana = (district) => {
            startingThanaList.value = globalValues.value.getThanas(district);
        };
        const getDestinationThana = (district) => {
            destinationThanaList.value = globalValues.value.getThanas(district);
        };

        const getTypesCategorys = () =>{
            Axios
            .get("api/data/tour-types-and-categories")
            .then((res) =>{
                if (res.data.response == "success") {
                    catagories.value = res.data.data.categories;
                    types.value = res.data.data.types;
                }
                else{
                    console.log(res.data.message);
                }
            })
            .catch((err) =>{
                showToast("error", "Error", "Could not fetch data. Try again!");
                console.log(err);
            })
        };
        getTypesCategorys();

        const getManagerData = () =>{
            var userData = store.getters.userData;
            var user = JSON.parse(userData);
            if(user.role == 'owner'){
                Axios
                .get("api/operator/agency-manager/list")
                .then((res) =>{
                    if (res.data.response == "success") {
                        managers.value = res.data.data.data;
                    }
                    else{
                        console.log(res.data.message);
                    }
                })
                .catch((err) =>{
                    showToast("error", "Error", "Could not fetch data. Try again!");
                    console.log(err);
                })
            }else{
                managers.value[0] = user;
                state.manager_id = user.id;
            }
        }
        getManagerData();

        const changeStartDate = () => {
            state.end_date = "";
            state.booking_deadline = "";
            state.cancellation_deadline = "";
        };

        const addPlaces =  () => {
            places.value.push('');
        };

        const deletePlaces =  (index) => {
            places.value.splice(index, 1);
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        }

        return{
            submitted,
            current,
            changeStartDate,

            getstartingDivision,
            getstartingDistrict,
            getstartingThana,
            getDestinationDivision,
            getDestinationDistrict,
            getDestinationThana,

            startingDivisionList,
            startingDistrictList,
            startingThanaList,
            destinationDivisionList,
            destinationDistrictList,
            destinationThanaList,

            types,
            catagories,
            managers,

            valid$,
            state,
            places,
            addPlaces,
            deletePlaces
        }
    },
    methods: {
        nextPage(isFormValid) {
            this.submitted = true;
            if (!isFormValid) {
                return;
            }
                this.$emit('next-page', {formData: {
                    title: this.state.title,
                    starting_point: this.state.starting_point,
                    destination: this.state.destination,
                    seat_total: this.state.seat_total, 
                    seat_booked: this.state.seat_booked, 
                    start_date: this.state.start_date, 
                    end_date: this.state.end_date, 
                    day: this.state.day, 
                    night: this.state.night, 
                    meals: this.state.meals,
                    tshirt: this.state.tshirt,
                    places: this.places,
                    type_id: this.state.type_id,
                    category_id: this.state.category_id,
                    manager_id: this.state.manager_id,
                    description: this.state.description,
                    booking_deadline: this.state.booking_deadline,
                    cancellation_deadline: this.state.cancellation_deadline,
                    refund_percentage: this.state.refund_percentage,
                    price_adults: this.state.price_adults,
                    discount_adults: this.state.discount_adults,
                    price_children: this.state.price_children,
                    discount_children: this.state.discount_children
                    },
                pageIndex: 0});
            }
    }
}
</script>

<style>

</style>